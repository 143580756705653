@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LeagueSpartanBold.ttf");
  font-family: "League Spartan";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LeagueSpartanMedium.ttf");
  font-family: "League Spartan";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterMedium.ttf");
  font-family: "Inter";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LeagueSpartanRegular.ttf");
  font-family: "League Spartan";
  font-weight: 400;
}
