body {
  margin: 0;
  padding: 0;
  font-family: League Spartan;
}

/*.container-xs {
  max-width: 1440px;
}*/

* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}

a {
  text-decoration: none;
  display: block;
}

input {
  background-color: transparent;
  width: 100%;
}

input:focus {
  outline: none;
}

.tab-panel--selected {
  animation-name: tabPanel;
  animation-duration: 300ms;
  animation-timing-function: linear;
}

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes tabPanel {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
table {
  table-layout: fixed;
}
.alice-carousel__dots-item {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}
.ps-menu-icon {
  width: unset !important;
  min-width: unset !important;
  justify-content: start !important;
  margin: 0 !important;
}
.ps-menu-button,
.ps-menu-icon {
  height: unset !important;
}

.ps-menu-button.activer {
  background-color: unset !important;
}

.ps-menu-button:hover {
  background-color: unset !important;
}
.ps-sidebar-container,
.ps-submenu-content {
  background-color: transparent !important;
  overflow: unset;
}
.ps-collapsed .ps-menu-icon {
  min-width: 100% !important;
  justify-content: center !important;
}
.ps-collapsed {
  width: 80px !important;
}
.ps-sidebar-container,
.ps-menu-root,
.ps-menu-root > ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ps-sidebar-root {
  min-width: unset !important;
  border: none !important;
}
.ps-collapsed,
.ps-collapsed .ps-menu-root,
.ps-collapsed .ps-menu-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: unset !important;
  margin-right: unset !important;
}
.ps-collapsed ul {
  align-items: center;
}
.ps-menu-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ps-sidebar-container::-webkit-scrollbar {
  width: 2px;
}
.e1svg {
  position: fixed;
    top: 0;
    left: 0;
}
.e3svg {
  position: fixed;
    top: 0;
    right: 0;
}
.e2svg {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 50%;
}
.e4svg {
  position: fixed;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 50%;
}
.css-16puer9 {
  background: linear-gradient(356.2deg, rgba(98, 67, 255, 0.55) 0.89%, rgb(115, 88, 255) 54.46%);
  width: 50px;
  height: 25px;
  border-radius: 9px;
  position: absolute;
  top: 120px;
  left: -5px;
  overflow: hidden;
  opacity: 0.2;
}
.css-fredw7 {
  background: linear-gradient(356.2deg, rgba(98, 67, 255, 0.55) 0.89%, rgb(115, 88, 255) 54.46%);
  width: 150px;
  height: 25px;
  border-radius: 9px;
  position: absolute;
  top: 160px;
  left: -5px;
  overflow: hidden;
  opacity: 0.2;
}
.css-awh48z {
  background: linear-gradient(356.2deg, rgba(98, 67, 255, 0.55) 0.89%, rgb(115, 88, 255) 54.46%);
  width: 80px;
  height: 25px;
  border-radius: 9px;
  position: absolute;
  top: 250px;
  left: 220px;
  overflow: hidden;
  opacity: 0.2;
}
.css-11tkosz {
  background: linear-gradient(356.2deg, rgba(98, 67, 255, 0.55) 0.89%, rgb(115, 88, 255) 54.46%);
  width: 35px;
  height: 25px;
  border-radius: 9px;
  position: absolute;
  top: 70px;
  right: 40px;
  overflow: hidden;
  opacity: 0.2;
}
.css-x2gc8h {
  background: linear-gradient(356.2deg, rgba(98, 67, 255, 0.55) 0.89%, rgb(115, 88, 255) 54.46%);
  width: 120px;
  height: 25px;
  border-radius: 9px;
  position: absolute;
  top: 110px;
  right: 10px;
  overflow: hidden;
  opacity: 0.2;
}
.css-1x7vn5a {
  background: linear-gradient(356.2deg, rgba(98, 67, 255, 0.55) 0.89%, rgb(115, 88, 255) 54.46%);
  width: 180px;
  height: 25px;
  border-radius: 9px;
  position: absolute;
  top: 300px;
  right: 10px;
  overflow: hidden;
  opacity: 0.2;
}
.css-1belss4 {
  background: linear-gradient(356.2deg, rgba(98, 67, 255, 0.55) 0.89%, rgb(115, 88, 255) 54.46%);
  width: 120px;
  height: 25px;
  border-radius: 9px;
  position: absolute;
  top: 340px;
  right: -5px;
  overflow: hidden;
  opacity: 0.2;
}
.masquesurvideo{
  background-color: transparent;
  width: 100%;
  height: 200px;
  position: absolute;
  z-index: 99;
}
.videofilmrecut .video-react .video-react-big-play-button {
  display: none;
}
.PlayerControlBar {
  height: 40px !important;
  font-size: 15px;
  color: rgb(123 97 255 / var(--tw-bg-opacity));
  align-items: center;
}
.PlayerControlBar .video-react-duration.video-react-time-control,
.PlayerControlBar .video-react-time-control.video-react-time-divider{
  display: none !important;
}
.sizebannierefilm{
  height: 500px;
  background-position: center;
}
.sizebannierefilm::after{
  content: " ";
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0px;
  z-index: -1;
}
.sizebannierefilm::before{
  content: " ";
  background-image: linear-gradient( rgb(0, 0, 0),rgba(0, 0, 0, 0));
  width: 100%;
  height: 500px;
  position: absolute;
  top: 500px;
}
.video-react .quality-select {
  background-color:#1e242c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  min-width: 100px;
  border-radius: 0px;
}

.video-react .quality-select:hover {
  background-color: #1e242c;
}

.popup-clip-recut{
  border: solid 1px #333;
  position: fixed;
}

.PlayerControlBar .video-react-current-time,.PlayerControlBar .video-react-time-control{
  height: auto !important;
}
.PlayerControlBar .ideo-react-icon-fullscreen{
  display: none !important;
}
.PlayerControlBar  .video-react-icon-fullscreen{
  /*opacity: 0 !important;*/
}
.PlayerBigPlayButton {
  width: 100px !important;
  height: 100px !important;
  background-color: rgb(100 69 255 / var(--tw-bg-opacity)) !important;
  border: none !important;
  border-radius: 50% !important;
  opacity: 0.6 !important;
}
.PlayerBigPlayButton::before {
  position: unset!important;
}

.blockinfovideo{
  align-items: start;
  padding: 10px 0px;
}

.menumenuclient{
  width: 300px;
  background-color: #1b1b1b;
}

.color-recut{
  color: rgb(176, 161, 255) !important;
}
.bg-color-recut{
  background-color: rgb(176, 161, 255) !important;
}
.iconpatheinpagefilm{
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-left: 20px;
}
.videofilmrecut .video-react .video-react-control-bar{
  display: none !important;
}
.blockButtonDownloadClip{
  display: none;
}
.container-blockButtonDownloadClip:hover .blockButtonDownloadClip{
  display: flex;
  opacity: 1;
  background-color: #1b1b1b99;
}
.btn-telechargement{
  padding: 15px;
  margin-top: 10px;
  background-color: #1b1b1b;
  border-radius: 10px;
  cursor: pointer;
}
.loading-recut{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: #1b1b1b88;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.btn-clip{
  background-color: #6445FF;
  min-width: 220px;
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
  font-size: 20px;
  color: #fff;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  float: right;
  margin: 10px;
  cursor: pointer;
  
}
.btn-clip:hover,.btn-telechargement:hover,.shadowhover:hover{
  box-shadow: 0em 0em 0em #fff, 0em 0em 0.5em #fff;
}

.shadowDownloadClick{
  box-shadow: 0em 0em 0em #ffffff55, 0em 0em 0.5em #ffffff55;
}

.popup-clip-recut .header{
  justify-content: space-between;
  align-items: center;
}



.container-blockFilmClipsquence .blockFilmClipsquence{
  padding: 00px;
  width: calc(100% - 100px);
  /* width:100%; */
  float: right;
  padding-left: 20px;
  padding-right: 20px;
}

.component-ClipSequence .container-blockFilmClipsquence .blockFilmClipsquence{
  width: 100% !important;
}

.component-ClipSequence .blockFilmClipsquence{
  padding: 0px;
}

.container-blockFilmClipsquence .blockFilmClipsquence .titreclip{
  height: 30px;
  border-bottom: solid 1px #fff;
  color: #fff;
  /* font-size: 20px; */
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipTime{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  color: #fff;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipTime .timeclip{
  width: 90px;
  border: none;
  height: 40px;
  text-align: center;
  margin: 5px;
  /* font-size: 22px; */
  border: solid 1px #fff;
  border-radius: 10px;
  /* padding: 10px; */
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipDuree {
  color: #fff;
  text-align: center;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame{
  position: relative;
  overflow: hidden;
  border-radius: 0px;
  overflow: hidden;
  margin-top: 20px;
  /* margin: 10px 30px; */
  /* border-width: 3px;
  border-style: solid ; */
  /* display: none; */
}


.component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame{
  border-radius: 20px;
  margin: 10px 0px;
  border-width: 3px;
  border-style: solid ;
  padding-left: 11px;
  padding-right: 11px;
}




.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .friseClip{
  position: absolute;
  overflow-x: auto;
  width: 100%;
  justify-content: space-between;
  top: -10px;
  left: 0px;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider{
  height: 70px;
  background:transparent;
}

.component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider{
  height: 50px;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb{
  background:  #6445FF;
  width: 20px;
  height: 100%;
  border-radius:unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: e-resize;
}

.component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb{
  background:  transparent;
}

.component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb[data-upper]{
  border-color:  #fff;
  border-bottom: solid 2px #fff;
  border-top: solid 2px #fff;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border-right: solid 2px #fff;
  margin-left: 10px; 
}

.component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb[data-lower]{
  /* border-left: solid 2px #fff;
  border-right:none !important;
  border-bottom-right-radius: 0px!important;
  border-top-right-radius: 0px!important;
  border-bottom-left-radius: 15px!important;
  border-top-left-radius: 15px!important;
  margin-left: -10px !important; */

  border-color:  #fff;
  border-bottom: solid 2px #fff;
  border-top: solid 2px #fff;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-left: solid 2px #fff;
  margin-left: -10px; 
} 



.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb > :last-child{
  border-left: solid 2px #fff;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb::after{
  content: "|";
  color: #fff;
  font-size: 35px;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__range{
  background:transparent;
  cursor: grab;
  position: relative;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__range::after{
  content: " ";
  background: #6445FF;
  width: 100%;
  height: 5px;
  display: block;
  position: absolute;
  top: 0px;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__range::before{
  content: " ";
  background: #6445FF;
  width: 100%;
  height: 5px;
  display: block;
  position: absolute;
  bottom: 0px;
}

.component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__range::before,
.component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__range::after{
  background: #fff;
  height: 2px;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb::before{
  content: " ";
  width: 10000px;
  height: 70px;
  background-color: #6b6b6baa;
  display: block;
  position: absolute;
  left: 20px;
  cursor: pointer;
}


.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb[data-lower]::before {
  left: unset;
  right: 20px;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider.invaliddure .range-slider__thumb,
.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider.invaliddure .range-slider__range,
.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider.invaliddure .range-slider__range::before,
.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider.invaliddure .range-slider__range::after
{
  background:#f1333388;
}

.component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider.invaliddure .range-slider__range::before,
.component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider.invaliddure .range-slider__range::after
{
  background:#fff;
}

.container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider.invaliddure .range-slider__range{
  /* background:#f1333399; */
}

.component-blockfilmclipframe .container-blockFilmClipsquence .btn-clip{
  margin-top: 10%;
  background-color: transparent;
  border: solid 4px #fff;
  border-radius: 50px;
  padding: 15px 40px;
  font-size: 25px;
}


.error-message{
  color: red;
}

#challenge-modal{
  background-color: #00000055;
}
.challenge-modal{
  border: solid 3px #f2c233;
  border-radius: 30px;
}
.challenge-modal-body-bg-image{
  background-position: top;
  background-size: cover;
  border-radius: 10px;
}
.challenge-modal-body{
  background-color: #000000bb;
  color: #fff;
}
.challenge-modal-body li{
  margin-bottom: 3px;
}

#challenge-modal .close-button {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
#challenge-modal .close-button::before,#challenge-modal .close-button::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 5px;
  background-color: black;
}
#challenge-modal .close-button::before {
  transform: rotate(45deg);
}
#challenge-modal .close-button::after {
  transform: rotate(-45deg);
}

#challenge-modal .video-button {
  cursor: pointer;
  background-color: #fbbf24; /* Yellow color */
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px; /* Full rounded corners */
  text-align: center;
  display: inline-block;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
}

.challenge{

}

.challenge .challenge-block{
  background-color: #111;
  color: #fff;
  border: solid 3px #f2c233;
  border-radius: 30px;
  /* background: radial-gradient(circle, #e9bd5055,#e9bd5022,#111); */
}

/* Styling for the play icon container */
.challenge .challenge-block .play-icon {
  width: 50px;
  height: 50px;
  background-color: #fcd34d; /* Tailwind's yellow-400 */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styling for the play button triangle */
.challenge .challenge-block .triangle {
  width: 0;
  height: 0;
  border-left: 12px solid white;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

/* Styling for the document icon container */
.challenge .challenge-block .document-icon {
  width: 50px;
  height: 50px;
  background-color: #fcd34d; /* Tailwind's yellow-400 */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Styling for the paper */
.challenge .challenge-block .paper {
  width: 20px;
  height: 28px;
  background-color: white;
  border-radius: 2px;
  position: relative;
}

/* Styling for the lines on the paper */
.challenge .challenge-block .line {
  width: 14px;
  height: 2px;
  background-color: #111827;
  position: absolute;
  left: 3px;
}

.challenge .challenge-block .line1 {
  top: 6px;
}

.challenge .challenge-block .line2 {
  top: 12px;
}

.challenge .challenge-block .line3 {
  top: 18px;
}



.challenge .challenge-block .loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

.canvasPlayerdisabledClipVideo{
  position: absolute;
  top: 0px;
  width: 100%;
  object-fit: contain;
}

.limit-texte{
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.challengesubdesc h2{
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 5px;
}

.challengesubdesc ul{
  list-style-type: disc;
  list-style-position: inside;
  margin-bottom: 5px;
  margin-left: 1.5rem;
}

.challengesubdesc ul li{
  margin-bottom: 5px;
}
.challengesubdesc p{
  margin-bottom: 1rem;
}


.block-hlssource .video-react-volume-menu-button-horizontal,
.block-hlssource .video-react-current-time,
.block-hlssource .video-react-time-control.video-react-time-divider,
.block-hlssource .video-react-duration,
.block-hlssource .video-react-icon-fullscreen{
  display: none;
}

.block-hlssource .video-react .video-react-play-progress::before{
  height: 48px;
  background-color: white;
  width: 2px;
  color: transparent;
  margin-top: -42px;
}

.block-hlssource .video-react-progress-holder.video-react-slider-horizontal.video-react-slider{
  margin-bottom: -40px;
}

.block-hlssource .video-react .video-react-play-progress:after {
  visibility: visible;
  top: -65px;
  background: white;
  font-size: 13px;
  font-weight: 700;
  padding: 4px 10px;
  border-radius: 17px;
  color: #0D0B0E;
  line-height: 5px;
  height: 15px;
}
.block-hlssource .video-react-play-control{
  width: 100px;
  font-size: 40px;
  margin-top: -15px;
  margin-left: -10px;
}


.block-hlssource .video-react-progress-holder.video-react-slider-horizontal.video-react-slider,
.block-hlssource .video-react-load-progress,
.block-hlssource .video-react-play-progress.video-react-slider-bar,
.block-hlssource .video-react-control-text{
  background-color: transparent;
}

.block-hlssource .video-react-progress-control.video-react-control{
  margin-left: 20px;
  margin-right: 20px;
}

.block-hlssource .video-react-control-bar{
  padding-left: 20px;
  padding-right: 20px;
  height: 90px;
}

/* .component-blockfilmclipframe{
  margin-top: 80px;
} */


.loader {
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.sc-eauhAA.bzRuyo.rdt_TableHeader {
  background-color: transparent;
  color: #fff;
}
.p-datatable .p-datatable-tbody > tr,
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd,
.p-paginator,
.p-datatable .p-datatable-thead > tr > th{
  background-color: transparent;
  color: #fff;
}

.react-multi-carousel-list .react-multi-carousel-item a{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--right {
  background-color: #a1a1a144 !important;
  border: solid 1px #a1a1a1;
}

.react-multiple-carousel__arrow--left:hover,
.react-multiple-carousel__arrow--right:hover {
  border-color: #FFF !important;
}

.ps-menu-root .active.menu-recut,
.ps-menu-root .menu-recut:hover{
  background-color: #e1e1e122;
  border-radius: 10px;
}

.ps-menu-root .active.menu-recut{
  position: relative;
}

.ps-menu-root .active.menu-recut .ps-menuitem-root::after{
  content: " ";
  background-color: #6445FF;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  left: 5px;
}

.p-datatable-table .p-datatable-tbody tr:hover{
  background-color: #e1e1e155!important;
}

.timeStartEndFilmClipFrame{

}

.dumptestclip{
  display: none;
}

.timeStartEndFilmClipFrame span {
  color: white;
  border-radius: 50px;
  font-size: 14px;
  padding: 5px;
  margin: 10px;
  margin-top: -12px;
}

.input-titreclip{
  background-color: #1a1a1a;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.newblockFilmClipTime{
  display: flex;
  color: white;
  background-color: #1a1a1a;
  padding: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 122px;
  font-weight: 800;
  height: 40px;
}

@media screen and (max-width: 1500px) {
  .sizebannierefilm{
    height: 500px;
  }
  .sizebannierefilm::before{
    top: 500px;
  }
}
@media screen and (max-width: 1030px) {
  .sizebannierefilm{
    height: 500px;
  }
  .sizebannierefilm::before{
    top: 500px;
  }
}
/* Styles pour les écrans avec une largeur inférieure à 900px */
@media screen and (max-width: 900px) {
  /* Vos styles ici */
  .PlayerControlBar {
    /* top: 350px; */
    height: 30px !important;
    font-size: unset;
  }
  .video-react .quality-select {
    min-width: 60px;
  }
  .sizebannierefilm{
    height: 500px;
  }
  .popup-clip-recut{
    position: unset;
    margin-left: calc(50% - 250px);
  }
  .block-popup-clip-recut{
    margin-top: 50px;
  }
  .block-popup-clip-recut>.btn-clip{
    margin-top: -45px;
  }
  .sizebannierefilm::before{
    top: 500px;
  }
}
@media screen and (max-width: 750px) {
  /* .blockFilmClipFrame{
    zoom: 70%;
  } */
  .component-blockfilmclipframe .blockFilmClipFrame{
    zoom: 100%;
  }
  .block-hlssource .video-react-play-control{
    display: none;
  }
  .container-blockFilmClipsquence .blockFilmClipsquence {
    width: 100%;
  }

  /* .block-hlssource .video-react-control-bar,
  .component-blockfilmclipframe .blockFilmClipFrame {
    zoom: 60%;
  } */

 

  .sizebannierefilm{
    height: 400px;
  }
  .popup-clip-recut{
    position: unset;
    margin-left: calc(50% - 250px);
  }
  .sizebannierefilm::before{
    top: 400px;
  }
  .component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame{
    border-width: 1px;
  }
  .component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__range::before, .component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__range::after {
    height: 1px;
  }
  .component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb[data-upper],
  .component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb[data-lower]{
    border-width: 1px;
  }
}
/* Styles pour les écrans avec une largeur inférieure à 600px */
@media screen and (max-width: 600px) {
  /* Vos styles ici */
  .PlayerControlBar {
    /* top: 250px; */
    height: 30px !important;
    font-size: unset;
  }
  .sizebannierefilm{
    height: 500px;
  }
  .sizebannierefilm::before{
    top: 500px;
  }

  .block-hlssource .video-react-control-bar{
    padding-left: 20px;
    padding-right: 20px;
    height: 50px;
    bottom:-23px;
  }
  .component-blockfilmclipframe .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider {
    height: 25px;
  }
  .block-hlssource .video-react-progress-holder.video-react-slider-horizontal.video-react-slider{
    margin-bottom:-25px;
  }

  /*.video-react .video-react-control{
    height: 50px;
  }*/

  .block-hlssource .video-react .video-react-play-progress::before{
    height: 30px;
    background-color: white;
    width: 2px;
    color: transparent;
    margin-top: -25px;
  }
  
  .block-hlssource .video-react .video-react-play-progress:after {
    visibility: visible;
    top: -35px;
    background: white;
    font-size: 10px;
    font-weight: 300;
    padding: 0px 5px;
    border-radius: 17px;
    color: #0D0B0E;
    line-height: 13px;
  }
  .container-blockFilmClipsquence .blockFilmClipsquence .blockFilmClipFrame .range-slider .range-slider__thumb::after {
    font-size: 15px;
  }

  .container-blockFilmClipsquence.container-101 .blockFilmClipsquence {
    padding-left: 35px;
    padding-right: 35px;
    margin-top: -25px;
  }
  .block-hlssource .video-react-progress-control.video-react-control{
    margin-left: 35px;
    margin-right: 35px;
  }
  .timeStartEndFilmClipFrame {
    width: calc(100% + 70px);
    margin-left: -35px;
    font-size: 10px;
  }
  .timeStartEndFilmClipFrame span {
    color: white;
    border-radius: 50px;
    font-size: 11px;
    padding: 3px;
    margin: 0px;
    margin-top: -30px;
  }
  /* .block-hlssource .video-react-control-bar,
  .component-blockfilmclipframe .blockFilmClipFrame {
    zoom: 40%;
  } */

  .shadowDownloadClick{
    left:calc((100vw - 300px) / 4 * -1);
  }
  
 
}

@media (max-width: 550px) {
  .btn-clip{
    margin-top:0px;
    min-width: 100px;
  }
  .popup-clip-recut{
    position: unset;
    margin-left: calc(50% - 250px);
  }
  .sizebannierefilm{
    height: 250px;
  }
  .sizebannierefilm::before{
    top: 250px;
  }
}

@media screen and (max-width: 500px) {
  .popup-clip-recut{
    width: 100%;
    margin-left:0px;
  }
}

@media screen and (max-width: 450px) {
  .sizebannierefilm{
    height: 250px;
  }
}

@media screen and (max-width: 383px) {
  .s-btn-page-film{
    font-size: 14px !important;
  }
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.5), -1.8em -1.8em 0 0em rgba(255,255,255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.5), 1.8em -1.8em 0 0em rgba(255,255,255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.5), 2.5em 0em 0 0em rgba(255,255,255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.5), 1.75em 1.75em 0 0em rgba(255,255,255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.5), 0em 2.5em 0 0em rgba(255,255,255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.5), -1.8em 1.8em 0 0em rgba(255,255,255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.5), -2.6em 0em 0 0em rgba(255,255,255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}



.old-style-recut-page-extrait .blockFilmClipsquence{
	width:100%;
}


/*******STYLE ADMIN ******/

.admininput input{
  padding-left: 20px;
}
.admininput{
  border-radius: 10px;
}

.admininput.textarea{
  height: max-content !important;
}
